import React from "react";

export default function HowItsWorks() {
  return (
    <div className="flex flex-col justify-center items-center mt-[200px] mb-[64px] w-[95%]">
      <div className="flex flex-col justify-center items-center">
        <h2 className="text-[#3C6772] text-[64px] font-about float-right">
          How it works?
        </h2>
        <p className="font-main text-[24px]">
          At CrazyPrints, creating your own personalized storybook is easy and
          fun. Here's how it works:
        </p>
      </div>
      <div className="flex flex-wrap gap-[83px] justify-center mt-[86px]">
        <div>
          <div>
            <div className="relative">
              <div className="w-[80px] h-[80px] bg-[#FFFFFF] absolute rounded-[50%] flex items-center justify-center z-20">
                <p className="font-main text-[40px] text-[#2E5058] text-center pb-[16px] font-bolt leading-[50px] mt-[15px]">
                  1
                </p>
              </div>
              <div className="w-[80px] h-[80px] bg-[#2E5058] absolute rounded-[50%] flex items-center justify-center top-[50%] left-[87%] opacity-[0.5]" />
              <div className="w-[270px] h-[270px] bg-[#2E5058] absolute rounded-[50%] flex items-center justify-center top-[33%] opacity-[0.5]" />
              <div className="flex justify-center items-center w-[367px] h-[367px] rounded-[50%] border-4 border-[#2E5058] relative">
                <div className="w-[322px] h-[322px] bg-[#2E5058] rounded-[50%]">
                  <div className="flex items-center flex-col px-[20px] py-[55px]">
                    <h2 className="font-main text-[20px] text-[#FFFFFF] text-center pb-[16px]">
                      Choose a theme and genre
                    </h2>
                    <p className="font-main text-[16px] text-[#FFFFFF] text-center">
                      First, you'll select a theme and genre for your book. We
                      offer a wide variety of options, from adventure and
                      fantasy to science fiction and romance, so you can create
                      a story that's tailored to your interests and preferences.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="relative">
              <div className="w-[80px] h-[80px] bg-[#FFFFFF] absolute rounded-[50%] flex items-center justify-center z-20">
                <p className="font-main text-[40px] text-[#4E9C7F] text-center pb-[16px] font-bolt leading-[50px] mt-[15px]">
                  2
                </p>
              </div>
              <div className="w-[80px] h-[80px] bg-[#4E9C7F] absolute rounded-[50%] flex items-center justify-center top-[50%] left-[87%] opacity-[0.5]" />
              <div className="w-[270px] h-[270px] bg-[#4E9C7F] absolute rounded-[50%] flex items-center justify-center top-[33%] opacity-[0.5]" />
              <div className="flex justify-center items-center w-[367px] h-[367px] rounded-[50%] border-4 border-[#4E9C7F] relative">
                <div className="w-[322px] h-[322px] bg-[#4E9C7F] rounded-[50%]">
                  <div className="flex items-center flex-col px-[20px] py-[55px]">
                    <h2 className="font-main text-[20px] text-[#FFFFFF] text-center pb-[16px]">
                      Fill out a questionnaire
                    </h2>
                    <p className="font-main text-[16px] text-[#FFFFFF] text-center">
                      Next, you'll fill out a questionnaire that will help our
                      AI algorithms understand your interests and preferences.
                      This will include questions about your favorite
                      characters, settings, and story elements.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="relative">
              <div className="w-[80px] h-[80px] bg-[#FFFFFF] absolute rounded-[50%] flex items-center justify-center z-20">
                <p className="font-main text-[40px] text-[#2E5058] text-center pb-[16px] font-bolt leading-[50px] mt-[15px]">
                  3
                </p>
              </div>
              <div className="w-[80px] h-[80px] bg-[#2E5058] absolute rounded-[50%] flex items-center justify-center top-[50%] left-[87%] opacity-[0.5]" />
              <div className="w-[270px] h-[270px] bg-[#2E5058] absolute rounded-[50%] flex items-center justify-center top-[33%] opacity-[0.5]" />
              <div className="flex justify-center items-center w-[367px] h-[367px] rounded-[50%] border-4 border-[#2E5058] relative">
                <div className="w-[322px] h-[322px] bg-[#2E5058] rounded-[50%]">
                  <div className="flex items-center flex-col px-[20px] py-[55px]">
                    <h2 className="font-main text-[20px] text-[#FFFFFF] text-center pb-[16px]">
                      Review and approve a digital proof
                    </h2>
                    <p className="font-main text-[16px] text-[#FFFFFF] text-center">
                      Our team of developers and illustrators will use the
                      information you provided to generate a unique story and
                      illustrations for your book. You will be able to review
                      and approve a digital proof of your book before it's
                      printed, and make any changes you'd like.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="relative">
              <div className="w-[80px] h-[80px] bg-[#FFFFFF] absolute rounded-[50%] flex items-center justify-center z-20">
                <p className="font-main text-[40px] text-[#4E9C7F] text-center pb-[16px] font-bolt leading-[50px] mt-[15px]">
                  4
                </p>
              </div>
              <div className="w-[80px] h-[80px] bg-[#4E9C7F] absolute rounded-[50%] flex items-center justify-center top-[50%] left-[87%] opacity-[0.5]" />
              <div className="w-[270px] h-[270px] bg-[#4E9C7F] absolute rounded-[50%] flex items-center justify-center top-[33%] opacity-[0.5]" />
              <div className="flex justify-center items-center w-[367px] h-[367px] rounded-[50%] border-4 border-[#4E9C7F] relative">
                <div className="w-[322px] h-[322px] bg-[#4E9C7F] rounded-[50%]">
                  <div className="flex items-center flex-col px-[20px] py-[55px]">
                    <h2 className="font-main text-[20px] text-[#FFFFFF] text-center pb-[16px]">
                      Receive your book
                    </h2>
                    <p className="font-main text-[16px] text-[#FFFFFF] text-center">
                      Once you have approved the digital proof, we will print
                      your book and ship it to you. It usually takes about 2-4
                      weeks for your book to be printed and delivered to you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="relative">
              <div className="w-[80px] h-[80px] bg-[#FFFFFF] absolute rounded-[50%] flex items-center justify-center z-20">
                <p className="font-main text-[40px] text-[#2E5058] text-center pb-[16px] font-bolt leading-[50px] mt-[15px]">
                  5
                </p>
              </div>
              <div className="w-[80px] h-[80px] bg-[#2E5058] absolute rounded-[50%] flex items-center justify-center top-[50%] left-[87%] opacity-[0.5]" />
              <div className="w-[270px] h-[270px] bg-[#2E5058] absolute rounded-[50%] flex items-center justify-center top-[33%] opacity-[0.5]" />
              <div className="flex justify-center items-center w-[367px] h-[367px] rounded-[50%] border-4 border-[#2E5058] relative">
                <div className="w-[322px] h-[322px] bg-[#2E5058] rounded-[50%]">
                  <div className="flex items-center flex-col px-[20px] py-[55px]">
                    <h2 className="font-main text-[20px] text-[#FFFFFF] text-center pb-[16px]">
                      Enjoy your personalized storybook
                    </h2>
                    <p className="font-main text-[16px] text-[#FFFFFF] text-center">
                      Your personalized storybook is now ready to be enjoyed!
                      The high-quality printing ensures that your book is a
                      beautiful and durable keepsake that will last for years to
                      come.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className="font-main text-[24px] mt-[64px] mx-[175px] text-center">
          Creating a personalized storybook with CrazyPrints is a fun, easy, and
          unique way to become the hero of your own story. With a wide variety
          of themes and genres to choose from, the possibilities are endless.
          Get started today and see for yourself how easy it is to create your
          own personalized storybook with CrazyPrints!
        </p>
      </div>
    </div>
  );
}

const MinusIco = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M16 17.5001C16.4 17.5001 16.5 17.5001 17 17.5001C17.5 17.5001 17 17.5001 17.4625 17.5H22.4125C22.7875 17.5 23.1 17.375 23.35 17.125C23.6 16.875 23.725 16.55 23.725 16.15C23.725 15.75 23.6 15.425 23.35 15.175C23.1 14.925 22.775 14.8 22.375 14.8H17.4625H14.7625V9.62502C14.7625 9.25002 14.8875 8.90002 15.1375 8.65002C14.8875 8.40002 16.1125 8.27502 16.1125 8.27502C16.1125 8.27502 15.3875 8.40002 15.1375 8.65002C14.8875 8.90002 14.7625 9.22503 14.7625 9.62502V14.8H9.5875C9.2125 14.8 8.9 14.925 8.65 15.175C8.4 15.425 8.275 15.75 8.275 16.15C8.275 16.55 8.4 16.875 8.65 17.125C8.9 17.375 9.225 17.5 9.625 17.5L14.5 17.5001C15 17.5001 14.5 17.5001 15 17.5001C15.5 17.5001 15.6 17.5001 16 17.5001ZM16.0375 31.9375C13.7625 31.9375 11.65 31.5313 9.7 30.7188C7.75 29.9063 6.0625 28.7875 4.6375 27.3625C3.2125 25.9375 2.09375 24.25 1.28125 22.3C0.46875 20.35 0.0625 18.2375 0.0625 15.9625C0.0625 13.7375 0.46875 11.6563 1.28125 9.71877C2.09375 7.78127 3.2125 6.09377 4.6375 4.65627C6.0625 3.21877 7.75 2.08752 9.7 1.26252C11.65 0.437524 13.7625 0.0250244 16.0375 0.0250244C18.2625 0.0250244 20.3438 0.437524 22.2812 1.26252C24.2188 2.08752 25.9062 3.21877 27.3438 4.65627C28.7812 6.09377 29.9125 7.78127 30.7375 9.71877C31.5625 11.6563 31.975 13.75 31.975 16C31.975 18.25 31.5625 20.35 30.7375 22.3C29.9125 24.25 28.7812 25.9375 27.3438 27.3625C25.9062 28.7875 24.2188 29.9063 22.2812 30.7188C20.3438 31.5313 18.2625 31.9375 16.0375 31.9375Z"
        fill="#7AB59F"
      />
    </svg>
  );
};

export default MinusIco;
